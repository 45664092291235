<template>
<section id="usage">
  <v-container>
    <div class="body">
      <h1>Acceptable User and Fair Usage</h1>
      <br />
      <h3 id="acceptable-use">Acceptable Use</h3>
      <p>
        In The Loop services, client software and websites (the “Service”) are
        intended to be used by millions of people, and we trust you to use the
        Service responsibly.
      </p>
      <p>
        This Acceptable Use and Fair Usage Policy should be read together with
        our Terms of Service.
      </p>
      <p>
        You agree not to misuse the Service. For example, you must not, and must
        not attempt to, use the services to do the following things:
      </p>
      <ul>
        <li>
          probe, scan, or test the vulnerability of any system or network;
        </li>
        <li>
          breach or otherwise circumvent any security or authentication
          measures;
        </li>
        <li>
          access, tamper with, or use non-public areas of the Service, shared
          areas of the Service you have not been invited to, In The Loop&#39;s
          (or our service providers’) computer systems;
        </li>
        <li>
          interfere with or disrupt any user, host, or network, for example by
          sending a virus, overloading, flooding, spamming, or mail-bombing any
          part of the Services;
        </li>
        <li>
          plant malware or otherwise use the Services to distribute malware;
        </li>
        <li>
          access or search the Services by any means other than our publicly
          supported interfaces (for example, “scraping”);
        </li>
        <li>
          send unsolicited communications, promotions or advertisements, or
          spam;
        </li>
        <li>
          publish anything that is fraudulent, misleading, or infringes another
          person’s rights;
        </li>
        <li>
          promote or advertise products or services other than your own without
          appropriate authorization;
        </li>
        <li>
          publish or share materials that are unlawfully pornographic or
          indecent, or that advocate bigotry, religious, racial or ethnic
          hatred;
        </li>
        <li>
          violate the law in any way, or to violate the privacy of others, or to
          defame others;
        </li>
        <li>
          Impersonate or misrepresent your affiliation with any person or
          entity;
        </li>
        <li>
          attempt to decipher, decompile, disassemble, or reverse engineer any
          of the software used to provide the Service;
        </li>
        <li>
          harm or threaten to harm other users in any way or interfere with, or
          attempt to interfere with, the access of any user, host or network,
          including without limitation, by sending a virus, overloading,
          flooding, spamming, or mail-bombing the Service;
        </li>
        <li>provide payment information belonging to a third party;</li>
        <li>
          use the Service in an abusive way contrary to its intended use, to its
          documentation or to In The Loop’s reasonable instructions;
        </li>
        <li>
          If you make available or share material using the Services for which
          you don&#39;t own the copyright or a license allowing you to do so, In
          The Loop may remove it without prior notice.
        </li>
      </ul>
      <p>Read our Terms of Service for more information</p>
      <br />
      <h3 id="evaluation">Evaluation</h3>
      <p>
        In The Loop evaluates your usage in comparison to typical levels of
        usage engaged in by other users. Based on such evaluation, In The Loop
        may determine that abnormal, unreasonable usage is occurring when
        compared to typical users.
      </p>
      <p>
        We understand that your use of the Services may be irregular and as such
        we will review and evaluate your use accordingly.
      </p>
      <br />
      <h3 id="notification">Notification</h3>
      <p>
        If In The Loop determines that your use is abnormal or unreasonable, we
        will contact you (via the email address provided in your account) to
        inform you of your usage, and may give you the opportunity to correct
        your usage or offer you to subscribe to a different plan. We also
        reserve the right to take any further action, including suspending
        immediately your account and your use of the Service, or closing your
        account.
      </p>
    </div>
  </v-container>
</section>
</template>

<script>
export default {
  name: "fair-usage",
};
</script>

<style scoped>

#usage {
  padding: 0 !important;
  background-color: black;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.body {
  margin-top: 100px;
}

</style>